@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}
// scale
@mixin scale($scale) {
   @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin cubic-bezier {
  -webkit-transition-timing-function: cubic-bezier(.19,1,.22,1);
  -moz-transition-timing-function: cubic-bezier(.19,1,.22,1);
  -ms-transition-timing-function: cubic-bezier(.19,1,.22,1);
  -o-transition-timing-function: cubic-bezier(.19,1,.22,1);
  transition-timing-function: cubic-bezier(.19,1,.22,1);
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin absolute($args...) {
  @include position(absolute, $args...);
}

// Buttons
@mixin button($bg) {
  background: $bg;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  padding: 10px 25px;
  &:hover,
  &:active,
  &:focus {
    background:lighten($bg, 5%);
    color: #fff;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}


// SVG Icons

$image-path: 'resources/images' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';
@mixin background-image($name, $size:false){
    background-image: url(#{$image-path}/#{$name}.svg);
    @if($size){
        background-size: $size;
    }
    .no-svg &{
        background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

        @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
          background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
        }
    }
}

@mixin icons-styling {
      display: block;
      height: 52px;
      width: 52px;
      background-position: center center;
      background-repeat: no-repeat;
      margin: 0 auto;
      background-size: auto;
}